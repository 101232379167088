import { Injectable } from '@angular/core';
import { StorageKeys } from '../../enums/storage-keys';
import { ApiService } from '../api-service/api.service';
import { Observable, interval, of } from 'rxjs';
import { switchMap, takeWhile, tap, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { SignupResponse } from '../../../auth-module/interfaces/signup-response';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProfileService } from '../../../profile-module/services/profile-service/profile.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import {CentrifugeService} from "../centrifuge-service/centrifuge.service";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  username: string;

  get isAuthenticated() {
    return !!localStorage.getItem(StorageKeys.Token);
  }

  constructor(
    private apiService: ApiService,
    private httpClient: HttpClient,
    private router: Router,
    private snackBar: MatSnackBar,
    private profileService: ProfileService,
    private centrifugeService: CentrifugeService
  ) {
    this.startRefresh();
  }

  login(data: {username: string, password: string}): Observable<{ token: string }> {
    return this.apiService.post<{token: string}>('login', data).pipe(
      tap(r => localStorage.setItem(StorageKeys.Token, r.token)),
      tap(() => this.startRefresh()),
      tap(r => this.router.navigateByUrl('/profile', {replaceUrl: true}))
    );
  }

  // signup(data: any): Observable<SignupResponse> {
  //   return this.apiService.post<SignupResponse>('accounts/register', data).pipe(
  //     tap(r => localStorage.setItem(StorageKeys.Token, r.token)),
  //     tap(r => this.router.navigateByUrl('/profile', {replaceUrl: true}))
  //   );
  // }
  
  signup(data: any): Observable<SignupResponse> {
    console.log('Signup method called with data:', data);
    return this.apiService.post<SignupResponse>('accounts/register', data).pipe(
      tap((response) => {
        console.log('Signup successful, response:', response);
        console.log('Attempting to navigate to /auth/sign-in');
        this.router.navigateByUrl('/auth/sign-in', {replaceUrl: true}).then(
          () => console.log('Navigation successful'),
          (err) => console.error('Navigation failed:', err)
        );
      }),
      catchError((error) => {
        console.error('Signup error:', error);
        return of(null);
      })
    );
  }

  checkInviteCode(code: any): Observable<SignupResponse> {
    return this.apiService.get<SignupResponse>(`accounts/check-invite-code/${code}`).pipe(
      tap(r => {
        return this.router.navigateByUrl(`/auth/sign-up?invite_code=${code}`, {replaceUrl: true});
      })
    );
  }

  logout(): void {
    localStorage.clear();
    this.profileService.clearCache();
    this.centrifugeService.disconnectWebSocket();
    this.router.navigateByUrl('/auth/start-page', {replaceUrl: true});
  }

  refreshToken(): Observable<{ token: string }> {
    return this.apiService.post<{ token: string }>('token/refresh', {token: localStorage.getItem(StorageKeys.Token)}).pipe(
      tap(r => localStorage.setItem(StorageKeys.Token, r.token))
    );
  }

  forgotPassword(data: {email: string}): Observable<any> {
    return this.apiService.post('accounts/password_reset', data);
  }

  resetPassword(uidb64: string, token: string, data): Observable<any> {
    /*return this.apiService.post(`auth/reset-password/${uidb64}/${token}`, data).pipe(
      tap(() => this.snackBar.open('Success', 'OK', {verticalPosition: 'top', duration: 3000}))
    );*/

    return this.httpClient.post(`${environment.apiUrl}/auth/reset-password/${uidb64}/${token}/`, data).pipe(
      tap(() => this.snackBar.open('Success', 'OK', {verticalPosition: 'top', duration: 3000}))
    );
  }

  /* TODO: refactor */
  private startRefresh(): void {
    interval( 2 * 60 * 1000).pipe(
      takeWhile(() => this.isAuthenticated),
      switchMap(() => this.refreshToken())
    ).subscribe(r => {
      if (r.hasOwnProperty('token')) {
        localStorage.setItem(StorageKeys.Token, r.token);
      }
    });
  }
}
